import connect from "react-redux/es/connect/connect";
import {Screen} from "./Screen";
import {Box, Button, CheckBox, Text} from "grommet/es6";
import {Link} from "react-router-dom";
import {LinkNext, Previous} from "grommet-icons";
import React from "react";
import {check} from "./actions";
import {
    cheesePerPerson,
    meatPerPerson,
    oignonPerPerson,
    picklesPerPerson,
    potatoesPerPerson,
    winePerPerson
} from "./constants";
import {inKg, inKgNum, plural} from "./helpers";
import {Image, ResponsiveContext} from "grommet";
import {apetiteObjectFromSlug, isValidNumberOfPeople} from "./reducers";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";

const quantityNumber = {
    "cheese": (people, alteration) => inKgNum(alteration * cheesePerPerson * people),
    "potatoes": (people, alteration) => inKgNum(alteration * potatoesPerPerson * people),
    "meat": (people, alteration) => inKgNum(alteration * meatPerPerson * people),
    "pickles": (people, alteration) => Math.ceil(alteration * (picklesPerPerson * people) / 220),
    "onions": (people, alteration) => Math.ceil(alteration * (oignonPerPerson * people)),
    "wine": (people, alteration) => Math.ceil(alteration * (winePerPerson * people)),
};
const quantity = {
    "cheese": (people, alteration) => `${inKg(alteration * cheesePerPerson * people)}`,
    "potatoes": (people, alteration) => `${inKg(alteration * potatoesPerPerson * people)}`,
    "meat": (people, alteration) => `${inKg(alteration * meatPerPerson * people)}`,
    "pickles": (people, alteration) => `${Math.ceil(alteration * (picklesPerPerson * people) / 220)} pot de 220g`,
    "onions": (people, alteration) => `${Math.ceil(alteration * (oignonPerPerson * people))} oignon${plural(Math.ceil(alteration * (oignonPerPerson * people)))}`,
    "wine": (people, alteration) => `${Math.ceil(alteration * (winePerPerson * people))} bouteille${plural(Math.ceil(alteration * (winePerPerson * people)))}`,
};
const quantityByPerson = {
    "cheese": alteration => `${inKg(alteration * cheesePerPerson)}/pers.`,
    "potatoes": alteration => `${inKg(alteration * potatoesPerPerson)}/pers.`,
    "meat": alteration => `${inKg(alteration * meatPerPerson)}/pers.`,
    "pickles": alteration => `un pot de 220g pour 10 pers.`,
    "onions": alteration => `un demi à un par pers.`,
    "wine": alteration => `une demi à une bouteille par pers.`,
};

const suggestColor = "brand";
const priceColor = "accent-1";

const emoji = people => people > 1 ? "👥" : "";

function ingredientPrice(ingredient, people, apetite, bracketPosition) {
    return ingredient.priceBracked[bracketPosition] * quantityNumber[ingredient.key](people, apetite.alteration);
}

function totalPrice(todo, people, apetite, bracketPosition) {
    return (todo.reduce((acc, ingredient) => acc + ingredientPrice(ingredient, people, apetite, bracketPosition), 0));
}

const RacletteForComp = ({match, todo, check}) => {
    const people = match.params.numberOfPeople;
    const apetite = apetiteObjectFromSlug(match.params.apetiteSlug || "");
    if (!isValidNumberOfPeople(people)) {
        return (
            <Screen>
                <h1>Vous avez voulu casser le site, c'est ça ?</h1>
            </Screen>
        )
    }
    return (
        <Screen>
            <Box
                pad="large"
                style={{
                    display: "block", //else, it breaks on Firefox
                }}
                fill
                overflow="auto"
            >
                <Box margin={{bottom: "large"}}>
                    <Box justify="center" direction="row" margin={{bottom: "small"}}>
                        <Link to="/combien-de-personnes">
                            <Button
                                label={<Previous/>}
                            />
                        </Link>
                        <Box justify="center">
                            <h2 style={{margin: 0}}>&nbsp;Raclette
                                pour {people} {people > 1 ? apetite.namePlural : apetite.name} {emoji(people)} :</h2>
                        </Box>
                    </Box>
                    <Box>
                        <Text color={priceColor}>
                            Prix total
                            entre {totalPrice(todo, people, apetite, 0).toFixed(0)}€
                            et {totalPrice(todo, people, apetite, 1).toFixed(0)}€
                            selon les ingrédients (soit {(totalPrice(todo, people, apetite, 0) / people).toFixed(0)}€
                            à {(totalPrice(todo, people, apetite, 1) / people).toFixed(0)}€ par personne)
                        </Text>
                    </Box>
                </Box>


                <Box style={{textAlign: "left", display: "inline-flex"}}>
                    {
                        todo.map(ingredient => <Box margin={{bottom: "medium"}} key={ingredient.key} pad="medium" background="white" style={{borderRadius: "1rem"}}>
                            <Box direction="row">
                                <CheckBox onChange={() => check(ingredient.key)} checked={ingredient.checked}/>
                                &nbsp;
                                <ResponsiveContext.Consumer>
                                    {size =>
                                        <Box direction={size === "small" ? "column" : "row"}
                                             pad="small"
                                             style={{textDecoration: ingredient.checked ? "line-through" : "none"}}>
                                            <h3 style={{margin: 0}}>{ingredient.name}</h3>
                                            <Text
                                                color={suggestColor}>&nbsp;({quantityByPerson[ingredient.key](apetite.alteration)})</Text>
                                            <Text
                                                color={priceColor}>&nbsp;entre {ingredientPrice(ingredient, people, apetite, 0).toFixed(0)}€
                                                et {ingredientPrice(ingredient, people, apetite, 1).toFixed(0)}€</Text>
                                        </Box>
                                    }
                                </ResponsiveContext.Consumer>
                            </Box>

                            <Box style={{display: ingredient.checked ? "none" : "flex"}}>
                                <Box justify="center" direction="row" alignSelf="start"
                                     margin={{left: "large", bottom: "small"}}>
                                    <LinkNext/><Text>&nbsp;{quantity[ingredient.key](people, apetite.alteration)}</Text>
                                </Box>

                                {ingredient.suggestions ?
                                    <Box margin={{left: "large"}}>
                                        <Text color={suggestColor}>Suggestions :</Text>
                                        {ingredient.suggestions.map((suggestion, i) => <Text margin={{left: "medium"}}
                                                                                             color={suggestColor}
                                                                                             key={i}>- {suggestion}</Text>)}
                                    </Box> : null
                                }
                            </Box>
                        </Box>)
                    }
                </Box>

                <Box aligneSelf="center" align="center" margin={{bottom: "large"}}>
                    <Box>
                        <Text>La raclette, ça se partage ! <span role="img" aria-label="quantity">🙂</span></Text>
                    </Box>
                    <Box direction="row">
                        <FacebookShareButton url="https://ma-raclette.fr"
                                             quote="Planifiez votre raclette pour prévoir les bonnes quantités et ne rien oublier !">
                            <FacebookIcon size={32} round/>
                        </FacebookShareButton>
                        <TwitterShareButton url="https://ma-raclette.fr"
                                            title="Planifiez votre raclette pour prévoir les bonnes quantités et ne rien oublier !">
                            <TwitterIcon size={32} round/>
                        </TwitterShareButton>
                    </Box>
                </Box>

                <Box style={{display: todo.every(ingredient => ingredient.checked) ? "flex" : "none"}}>
                    <Box direction="row" alignSelf="center">
                        <Box height="small" width="small" border>
                            <Image src="https://i.giphy.com/media/zf2143bX6p2uY/giphy.gif" fit="cover"/>
                        </Box>
                        <Box height="small" width="small" border>
                            <Image src="https://i.giphy.com/media/10hO3rDNqqg2Xe/giphy.gif" fit="cover"/>
                        </Box>
                    </Box>
                    <h3 style={{margin: 0}}><span role="img" aria-label="party">🎉</span> La fête peut commencer ! <span
                        role="img" aria-label="party">🎉</span></h3>
                </Box>
            </Box>
        </Screen>
    )
};

const mapStateToProps = state => {
    return {
        todo: state.todo,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        check: key => {
            dispatch(check(key))
        }
    }
};
export const RacletteFor = connect(mapStateToProps, mapDispatchToProps)(RacletteForComp);
