import {Screen} from "./Screen";
import {Box, Text} from "grommet/es6";
import React from "react";

export const Donate = () => {
    return <Screen pad="large">
        <Box margin={{bottom: "large"}}><h2 style={{margin: 0}}>Donation</h2></Box>
        <Box margin={{bottom: "large"}}><Text>J'ai réalisé ce projet d'utilité publique sur mon temps libre. S'il vous a aidé, n'hésitez pas à m'offrir une bière <span role="img" aria-label="beer">🍻</span> (ou une tranche de raclette <span role="img" aria-label="cheese">🧀</span>) !</Text></Box>
        <Box><a
            className="dbox-donation-button"
            href="https://donorbox.org/ma-raclette"
            style={{
                background: "#2d81c5 url(https://d1iczxrky3cnb2.cloudfront.net/red_logo.png) no-repeat 37px center",
                color: "#fff",
                textDecoration: "none",
                fontFamily: "Verdana,sans-serif",
                display: "inline-block",
                fontSize: "16px",
                padding: "15px 38px 15px 75px",
                borderRadius: "2px",
                boxShadow: "0 1px 0 0 #1f5a89",
                textShadow: "0 1px rgba(0, 0, 0, 0.3)"
            }}
            target="_blank"
            rel="noopener noreferrer"
        >Donate</a></Box>
    </Screen>;
};
