import {Screen} from "./Screen";
import {Link} from "react-router-dom";
import {Button, Image} from "grommet/es6";
import React from "react";
import {Box, Text} from "grommet";

export const IndexPage = () =>
    <Screen pad="large">
        <Box margin={{bottom: "large"}}>
            <h1 style={{margin: "0 0 48px 0"}}><span role="img" aria-label="cheese">🧀</span> Ma Raclette</h1>
            <Box height="small" width="small" alignSelf="center" margin={{bottom: "large"}} border>
                <Image src="https://i.giphy.com/media/241N15OgQ8CNNF00DQ/giphy.gif" fit="cover"/>
            </Box>
            <Text>Planifiez votre raclette pour prévoir les bonnes quantités <span role="img"
                                                                                   aria-label="quantity">📏</span> et ne
                rien oublier <span role="img" aria-label="brain exploding">🤯</span> !</Text>
        </Box>
        <Link to="/combien-de-personnes">
            <Button
                label="C'est parti !"
                primary
            />
        </Link>
    </Screen>;
