import {combineReducers} from "redux";

export const isValidNumberOfPeople = numberOfPeople =>
    Number.isInteger(Number(numberOfPeople)) && Number(numberOfPeople) >= 1 && Number(numberOfPeople) <= 1000;

const numberOfPeople = (state = "1", action) => {
    switch (action.type) {
        case "UPDATE_NUMBER_OF_PEOPLE":
            if (action.numberOfPeople === "") {
                return "";
            } else if (isValidNumberOfPeople(action.numberOfPeople)) {
                return String(Number(action.numberOfPeople));
            }
            return state;
        default:
            return state;
    }
};
const initialApetiteState = {
    value: 1,
    apetite: "raisonnable",
    name: "personne",
    namePlural: "personnes",
    alteration: 1,
    alterationText: "",
    slug: ""
};
const apetiteObjects = [
    {
        value: 0,
        apetite: "au régime",
        name: "personne au régime",
        namePlural: "personnes au régime",
        alteration: 0.8,
        alterationText: "-20%",
        slug: "au-regime"
    },
    initialApetiteState,
    {
        value: 2,
        apetite: "gros mangeur",
        name: "gros mangeur",
        namePlural: "gros mangeurs",
        alteration: 1.2,
        alterationText: "+20%",
        slug: "gros-mangeur"
    },
    {
        value: 3,
        apetite: "mangeur gargantuesque",
        name: "mangeur gargantuesque",
        namePlural: "mangeurs gargantuesques",
        alteration: 1.5,
        alterationText: "+50%",
        slug: "mangeur-gargantuesque"
    }
];
export const apetiteObjectFromSlug = slug => apetiteObjects.find(apetiteObject => apetiteObject.slug === slug);
const apetite = (state = initialApetiteState, action) => {
    switch (action.type) {
        case "APETITE":
            return apetiteObjects[action.apetiteScore];
        default:
            return state;
    }
};
export const initialTodoState = [
    {
        key: "cheese",
        name: "🧀 Fromage à raclette",
        suggestions: [
            "raclette",
            "raclette au poivre",
            "saint-nectaire",
            "morbier",
        ],
        checked: false,
        priceBracked: [10, 15] //prix au kg en €
    },
    {
        key: "potatoes",
        name: "🥔 Pommes de terre",
        checked: false,
        priceBracked: [1, 3] //prix au kg en €
    },
    {
        key: "meat",
        name: "🥓 Charcuterie",
        suggestions: [
            "rosette",
            "jambon de pays",
            "chorizo",
            "jambon blanc",
            "viande de grison",
            "coppa"
        ],
        checked: false,
        priceBracked: [8, 50] //prix au kg en €
    },
    {
        key: "pickles",
        name: "🥒 Cornichons",
        checked: false,
        priceBracked: [2, 5] //prix bocal en €
    },
    {
        key: "onions",
        name: "🔪 Oignons",
        checked: false,
        priceBracked: [1, 7] //prix au kg en €
    },
    {
        key: "wine",
        name: "🍷 Vin",
        suggestions: [
            "vin blanc de Savoie",
            "saint Joseph blanc",
            "Bourgueil rouge",
        ],
        checked: false,
        priceBracked: [4, 20] // prix par bouteille en €
    }
];
export const todo = (state = initialTodoState, action) => {
    switch (action.type) {
        case "CHECK":
            return state.map(ingredient => {
                    if (ingredient.key === action.key) {
                        return {
                            ...ingredient,
                            checked: !ingredient.checked
                        }
                    } else {
                        return ingredient
                    }
                }
            );
        default:
            return state;
    }
};
export const sendContactMessageInitialState = {
    status: "UNSENT",
    success: null
};
export const sendContactMessage = (state = sendContactMessageInitialState, action) => {
    switch (action.type) {
        case "SEND_CONTACT_MESSAGE_SUCCESS":
            return {
                status: "SEND_CONTACT_MESSAGE_SUCCESS",
                message: "Votre message a bien été envoyé !"
            };
        case "SEND_CONTACT_MESSAGE_ERROR":
            return {
                status: "SEND_CONTACT_MESSAGE_ERROR",
                message: "Un problème est survenu lors de l'envoi du message... :("
            };
        case "HIDE_CONTACT_MESSAGE":
            return sendContactMessageInitialState;
        default:
            return state;
    }
};

const combinedReducers = combineReducers({numberOfPeople, apetite, todo, sendContactMessage});
export default combinedReducers;
