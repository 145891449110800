import {Box, Grid, Grommet, Text} from "grommet/es6";
import {grommet} from "grommet";
import {Link} from "react-router-dom";
import React from "react";

const bgColor = "#FFFC66";
export const Screen = props =>
    <Grommet theme={grommet} full>
        <Grid
            fill
            rows={["flex", "4.5rem"]}
            columns={[]}
            areas={[
                {name: "main", start: [0, 0], end: [1, 0]},
                {name: "footer", start: [0, 1], end: [1, 1]}
            ]}
        >
            <Box
                gridArea="main"
                background={bgColor}
                align="center"
                alignContent="center"
                justify="center"
                style={{
                    textAlign: "center",
                }}
                {...props}
            />
            <Box
                align="center"
                alignContent="center"
                justify="center"
                background="dark-3"
                gridArea="footer"
                style={{
                    textAlign: "center"
                }}
            >
                <Text>
                    Fait par <a style={{color: "#fff"}}
                                href={"https://twitter.com/vincentdnl"}
                                target="_blank"
                                rel="noopener noreferrer">@vincentdnl</a>.
                    Vous avez trouvé ce site sympa ? <Link style={{color: "#fff"}} to={"/donate"}>Par ici !</Link> Un
                    bug ou une idée pour ce site ? <Link style={{color: "#fff"}} to={"/feedback"}>C'est là !</Link>
                </Text>
            </Box>
        </Grid>
    </Grommet>;
