import {Screen} from "./Screen";
import {Button, FormField, TextInput} from "grommet/es6";
import {Link} from "react-router-dom";
import React from "react";
import connect from "react-redux/es/connect/connect";
import {updateApetite, updateNumberOfPeople} from "./actions";
import {Box, RangeInput, Text} from "grommet";

const emoji = people => people > 1 ? "🎉" : "";
const HowManyPeopleComp = ({numberOfPeople, apetite, updateApetite, updateNumberOfPeople}) =>
    <Screen pad="large">
        <Box margin={{bottom: "large"}}>
            <Box style={{margin: 0, marginBottom: "48px"}}>
                <h2 style={{margin: 0, marginBottom: "24px"}}>
                    Combien de personnes <span role="img" aria-label="people">👥</span> ?
                </h2>
                <RangeInput
                    min={1}
                    max={20}
                    step={1}
                    value={Number.isInteger(Number(numberOfPeople)) ? Number(numberOfPeople) : 0}
                    style={{margin: 0, marginBottom: "12px"}}
                    onChange={event => updateNumberOfPeople(event.target.value)}
                />
                <FormField>
                    <TextInput
                        key="numberOfPeople"
                        style={{
                            width: "20rem",
                            textAlign: "center"
                        }}
                        value={numberOfPeople}
                        onChange={event => updateNumberOfPeople(event.target.value)}
                    />
                </FormField>
            </Box>

            <Box>
                <h2 style={{margin: 0, marginBottom: "24px"}}>Plutôt au régime ou gros mangeurs ?</h2>
                <RangeInput
                    min={0}
                    max={3}
                    step={1}
                    value={apetite.value}
                    style={{margin: 0, marginBottom: "24px"}}
                    onChange={event => updateApetite(event.target.value)}
                />
                <Text>{apetite.apetite} <b>{apetite.alterationText}</b></Text>
            </Box>
        </Box>
        <Link to={`/une-raclette-pour/${numberOfPeople}/${apetite.slug}`}>
            <Button
                label={`Et une raclette pour ${numberOfPeople} ${numberOfPeople > 1 ? apetite.namePlural : apetite.name} ${emoji(numberOfPeople)} !`}
                primary
            />
        </Link>
    </Screen>;

const mapStateToProps = state => {
    return {
        numberOfPeople: state.numberOfPeople,
        apetite: state.apetite,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        updateNumberOfPeople: numberOfPeope => {
            dispatch(updateNumberOfPeople(numberOfPeope))
        },
        updateApetite: apetiteScore => {
            dispatch(updateApetite(apetiteScore))
        },
    }
};

export const HowManyPeople = connect(mapStateToProps, mapDispatchToProps)(HowManyPeopleComp);
