import React from 'react';
import {BrowserRouter, Route, withRouter} from "react-router-dom";
import {Provider} from "react-redux";
import reducers from "./reducers";
import {IndexPage} from "./IndexPage";
import {HowManyPeople} from "./HowManyPeople";
import {RacletteFor} from "./RacletteFor";
import {Donate} from "./Donate";
import withAnalytics, {initAnalytics} from 'react-with-analytics';
import Feedback from "./Feedback";
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: "https://59bf6634fd774a0c99f588d0139f7353@sentry.io/1332878"
});

initAnalytics('UA-111606773-3');

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancer(applyMiddleware(thunk)),
);

const Root = () =>
    <>
        <Route path="/" exact component={IndexPage}/>
        <Route path="/combien-de-personnes/" exact component={HowManyPeople}/>
        <Route path="/une-raclette-pour/:numberOfPeople/:apetiteSlug?" exact component={RacletteFor}/>
        <Route path="/donate/" exact component={Donate}/>
        <Route path="/feedback/" exact component={Feedback}/>
    </>;

const App = withRouter(withAnalytics(Root));

const AppWithRouter = () => (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

export default AppWithRouter;
