import {url} from "./constants";

export const updateNumberOfPeople = numberOfPeople => {
    return {
        type: "UPDATE_NUMBER_OF_PEOPLE",
        numberOfPeople
    }
};
export const updateApetite = apetiteScore => {
    return {
        type: "APETITE",
        apetiteScore
    }
};
export const check = key => {
    return {
        type: "CHECK",
        key
    }
};
export const sendContactMessage = data => {
    return dispatch => {
        fetch(`${url}/contact-me`, {
            method: 'POST',
            body: data,
        })
            .then(res => res.json())
            .then(event => {
                console.log(event);
                if (event.success) {
                    dispatch(sendContactMessageSuccess());
                    setTimeout(() => {
                        dispatch(hideContactMessage())
                    }, 10000)
                } else {
                    dispatch(sendContactMessageError());
                    setTimeout(() => {
                        dispatch(hideContactMessage())
                    }, 10000)
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(sendContactMessageError());
                setTimeout(() => {
                    dispatch(hideContactMessage())
                }, 10000)
            });
    }
};
export const sendContactMessageSuccess = () => {
    return {
        type: "SEND_CONTACT_MESSAGE_SUCCESS"
    }
};
export const sendContactMessageError = () => {
    return {
        type: "SEND_CONTACT_MESSAGE_ERROR"
    }
};
export const hideContactMessage = () => {
    return {
        type: "HIDE_CONTACT_MESSAGE"
    }
};
