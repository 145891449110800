import {Screen} from "./Screen";
import {Box, Text} from "grommet/es6";
import React from "react";
import {Button, FormField, TextArea, TextInput} from "grommet";
import {connect} from "react-redux";
import {sendContactMessage} from "./actions";
import styled from "styled-components";

const Status = styled("div")`
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    
    p {
        margin: 0;
        width: 100%;
    }
`;

export const successGreen = "#69c27b";
export const failureRed = "#ff5e5b";

function styleFromStatus(status) {
    if (status === "SEND_CONTACT_MESSAGE_SUCCESS") {
        return {
            display: "flex",
            backgroundColor: successGreen
        }
    } else if (status === "SEND_CONTACT_MESSAGE_ERROR") {
        return {
            display: "flex",
            backgroundColor: failureRed
        }
    } else {
        return {display: "none"};
    }
}

export const Feedback = ({onContactFormSubmit, sendContactMessage}) => {
    return <Screen pad="large">
        <Box margin={{bottom: "large"}}><h2 style={{margin: 0}}>Feedback</h2></Box>
        <Box margin={{bottom: "large"}}><Text>Un bug ? Impossible ! Une suggestion d'amélioration pour ce site ? Ou
            juste un message à me faire passer ? C'est ici, grâce à ce formulaire ou via Twitter (<a
                style={{color: "#7D4CDB"}}
                href={"https://twitter.com/vincentdnl"}
                target="_blank"
                rel="noopener noreferrer">@vincentdnl</a>)</Text>
        </Box>
        <Box>
            <Status style={styleFromStatus(sendContactMessage.status)}>
                <p>{sendContactMessage.message}</p>
            </Status>
            <form onSubmit={event => onContactFormSubmit(event)}>
                <FormField>
                    {/*<label htmlFor="email">E-mail</label>*/}
                    <TextInput type="email" id="email" name="email" placeholder="E-mail"/>
                </FormField>

                <FormField>
                    {/*<label htmlFor="message">Message</label>*/}
                    <TextArea id="message" name="message" rows="3" placeholder="Message" style={{resize: "none"}}/>
                </FormField>

                <Box>
                    <div className="g-recaptcha" data-sitekey="6Lfv03QUAAAAAJuo9VfqzF3uaXwIoKY0VrJrdxkm"/>
                    <Button type="submit" label="Envoyer" primary/>
                </Box>
            </form>
        </Box>
    </Screen>;
};

const mapStateToProps = state => {
    return {
        sendContactMessage: state.sendContactMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onContactFormSubmit: event => {
            event.preventDefault();
            const data = new FormData(event.target);
            console.log(data);
            dispatch(sendContactMessage(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
